@tailwind base;
@tailwind components;
@tailwind utilities;

#ako-configurator {
  color: #000;
}

.akoconf-form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%23ae1232%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e") !important;
}

.akoconf-underline {
  text-decoration: underline;
}

.akoconf-bg-ako-red { background-color: #ae1232; }
.akoconf-bg-ako-green { background-color: #3f9c3b; }
.akoconf-bg-ako-black { background-color: #000; }
.akoconf-bg-ako-gray { background-color: #717171; }
.akoconf-bg-ako-gray-medium { background-color: #e1e1e1; }
.akoconf-bg-ako-gray-clear { background-color: #e6e6e6; }
.akoconf-bg-overlay { background-color: #979797; }

.akoconf-text-ako-red { color: #ae1232; }
.akoconf-text-ako-green { color: #3f9c3b; }
.akoconf-text-ako-black { color: #000; }
.akoconf-text-ako-gray { color: #717171; }
.akoconf-text-ako-gray-medium { color: #e1e1e1; }
.akoconf-text-ako-gray-clear { color: #e6e6e6; }
.akoconf-text-overlay { color: #979797; }

.akoconf-border-ako-red { border-color: #ae1232; }
.akoconf-border-ako-green { border-color: #3f9c3b; }
.akoconf-border-ako-black { border-color: #000; }
.akoconf-border-ako-gray { border-color: #717171; }
.akoconf-border-ako-gray-medium { border-color: #e1e1e1; }
.akoconf-border-ako-gray-clear { border-color: #e6e6e6; }
.akoconf-border-overlay { border-color: #979797; }

.akoconf-shadow-table { box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.3); }
.akoconf-shadow-button { box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.3); }

.akoconf-form-input.akoconf-border-ako-red { border-color: #ae1232 !important; }
.akoconf-form-select.akoconf-border-ako-red { border-color: #ae1232 !important; }
